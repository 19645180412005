export const DashboardIcon: React.FC = () => {
    return <svg height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="5.32983" y="0.5" width="21.9787" height="27.7234" rx="1" fill="#E8FFEC" stroke="#3A3A3A"/>
        <rect x="3.41479" y="1.77661" width="21.9787" height="27.7234" rx="1" fill="#E8FFEC" stroke="#3A3A3A"/>
        <rect x="0.5" y="4.60632" width="4.75" height="1.9375" rx="0.96875" fill="#F3FFF5" stroke="#3A3A3A"/>
        <rect x="0.5" y="8.4361" width="4.82979" height="1.95745" rx="0.978723" fill="#F3FFF5" stroke="#3A3A3A"/>
        <rect x="0.5" y="12.9042" width="4.82979" height="1.95745" rx="0.978723" fill="#F3FFF5" stroke="#3A3A3A"/>
        <rect x="0.5" y="25.0319" width="4.82979" height="1.95745" rx="0.978723" fill="#F3FFF5" stroke="#3A3A3A"/>
        <rect x="0.5" y="17.3724" width="4.82979" height="1.95745" rx="0.978723" fill="#F3FFF5" stroke="#3A3A3A"/>
        <rect x="0.5" y="21.2021" width="4.82979" height="1.95745" rx="0.978723" fill="#F3FFF5" stroke="#3A3A3A"/>
        <path d="M20.6875 6.5625V17.9767H16.5V10.7644C16.5 9.65001 16.9412 8.58122 17.7265 7.79321C18.5118 7.00519 19.5769 6.5625 20.6875 6.5625Z" fill="#56B165"/>
        <path d="M18.25 17.914H20.6875V23.182C20.6875 23.4981 20.5624 23.8012 20.3397 24.0246C20.117 24.2481 19.8149 24.3736 19.5 24.3736H19.4375C19.1226 24.3736 18.8205 24.2481 18.5978 24.0246C18.3751 23.8012 18.25 23.4981 18.25 23.182V17.914Z" fill="#56B165"/>
        <path d="M11.8125 24.3736C11.4313 24.3736 11.0656 24.2217 10.796 23.9512C10.5265 23.6806 10.375 23.3138 10.375 22.9312L11 15.0918H12.6875L13.3125 22.9312C13.3127 23.126 13.2735 23.3189 13.1974 23.4981C13.1213 23.6773 13.0098 23.8392 12.8696 23.974C12.7294 24.1088 12.5635 24.2137 12.3818 24.2824C12.2001 24.3511 12.0065 24.3821 11.8125 24.3736Z" fill="#56B165"/>
        <path d="M11.8125 15.0918C13.642 15.0918 15.125 13.2386 15.125 10.9526C15.125 8.66655 13.642 6.81336 11.8125 6.81336C10.5596 6.81336 9.46912 7.68259 8.90627 8.96478C8.64728 9.55476 8.5 10.2322 8.5 10.9526C8.5 13.2386 9.98307 15.0918 11.8125 15.0918Z" fill="#56B165"/>
        <path d="M20.6875 6.5625V17.9767H16.5V10.7644C16.5 9.65001 16.9412 8.58122 17.7265 7.79321C18.5118 7.00519 19.5769 6.5625 20.6875 6.5625Z" stroke="#3A3A3A" strokeWidth="0.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.25 17.914H20.6875V23.182C20.6875 23.4981 20.5624 23.8012 20.3397 24.0246C20.117 24.2481 19.8149 24.3736 19.5 24.3736H19.4375C19.1226 24.3736 18.8205 24.2481 18.5978 24.0246C18.3751 23.8012 18.25 23.4981 18.25 23.182V17.914Z" stroke="#3A3A3A" strokeWidth="0.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.8125 24.3736C11.4313 24.3736 11.0656 24.2217 10.796 23.9512C10.5265 23.6806 10.375 23.3138 10.375 22.9312L11 15.0918H12.6875L13.3125 22.9312C13.3127 23.126 13.2735 23.3189 13.1974 23.4981C13.1213 23.6773 13.0098 23.8392 12.8696 23.974C12.7294 24.1088 12.5635 24.2137 12.3818 24.2824C12.2001 24.3511 12.0065 24.3821 11.8125 24.3736Z" stroke="#3A3A3A" strokeWidth="0.3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.8125 15.0918C13.642 15.0918 15.125 13.2386 15.125 10.9526C15.125 8.66655 13.642 6.81336 11.8125 6.81336C10.5596 6.81336 9.46912 7.68259 8.90627 8.96478C8.64728 9.55476 8.5 10.2322 8.5 10.9526C8.5 13.2386 9.98307 15.0918 11.8125 15.0918Z" stroke="#3A3A3A" strokeWidth="0.3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>      
};
