import { useEffect, useState } from 'react';

import { getRecipes } from '../../services/RecipeService';

import { Recipe } from '../../types/Recipe';

import { RecipeCard } from './RecipeCard/RecipeCard';

import './DashboardRecipesPage.scss';

function DashboardRecipesPage() {
    const [isLoadingRecipes, setIsLoadingRecipes] = useState<boolean>(true);
    const [recipes, setRecipes] = useState<Recipe[]>([]);

    useEffect(() => {
        fetchRecipes();
    }, [])

    const fetchRecipes = async () => {
        setIsLoadingRecipes(true);
        await getRecipes()
            .then(recipes => {
                setRecipes(recipes);
            })
            .catch(error => {})
            .finally(() => {
                setIsLoadingRecipes(false);
            });
    };

    return <div className="dashboard-recipes-page">
        <div className='hero'>
            <h1 className='title'>Ricette</h1>
        </div>
        <div className='content'>
            <div className='recipes'>
                {recipes.map(recipe =>
                    <RecipeCard
                        recipe={recipe}
                    />
                )}
            </div>
        </div>
    </ div>
}

export default DashboardRecipesPage;