import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

import "./Button.scss";

interface Props {
    active?: boolean,
    children?: any,
    className?: string,
    disabled?: boolean,
    isLoading?: boolean,
    label: string,
    loadingLabel?: string,
    type?: "button" | "submit" | "reset" | undefined,
    onClick?: () => void,
}

export const Button: React.FC<Props> = (props: Props) => {

    return (
        <button 
            className={"button " 
                        + (props.className ? props.className + ' ' : '') 
                        + (props.active ? 'active ' : '') 
                        + (props.isLoading ? 'loading ' : '')
                        + (props.disabled ? 'disabled ' : '')
                    }
            type={props.type}
            onClick={!props.disabled ? () => props.onClick?.() : undefined}
        >
            {props.children}
            {props.isLoading ? 
                <>
                    <LoadingSpinner color="#FFF"/>
                    {props.loadingLabel && <span className="loading-label">{props.loadingLabel}</span>}
                </> :
                <span>{props.label}</span>
            }
        </button>
    );
};
