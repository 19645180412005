import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PageLoader from '../../components/PageLoader/PageLoader';

import Logo from "../../images/logo-fun-vegetables.png";

import './RegistrationThankYouPage.scss';

function RegistrationThankYouPage() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/diario-alimentare');
        }, 7000)
    }, [])

    return (  
        <div className="registration-thank-you-page">
            <div className='content-wrapper'>
                <div className="logo-section">
                    <img className='logo-image' src={Logo} alt="Logo Fun Vegetables" />
                </div>
                <div className="message-section">
                    <p className='font-bold text-xxl title'>Complimenti!<br/> La tua registrazione è andata a buon fine.</p>
                    <p className='text-small text'>Stai per essere indirizzato sulla piattaforma FunVegetables.</p>
                    <p className='text-small text'>Da qui potrai compilare il <b>Diario Alimentare</b> e partecipare a tutti i <b>Contest</b> proposti!</p>
                </div>
                <PageLoader />
            </div>
        </div>
    );
}

export default RegistrationThankYouPage;