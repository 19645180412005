
import { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PageLoader from "../PageLoader/PageLoader";

interface Props {
  isAllowedFunction: () => Promise<boolean>,
  redirectPath?: string,
  children?: any,
}

export const ProtectedRoute = ({isAllowedFunction, redirectPath = '/accedi', children}: Props) => {
    const [isAllowed, setIsAllowed] = useState<boolean>();

    useEffect(() => {
        setTimeout(() => isAllowedFunction().then(response => {
          setIsAllowed(response);
        }), 500)
    }, []);

    if(isAllowed == null) {
      return <PageLoader />
    } 

    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children ? children : <Outlet />;
};