import { useEffect, useState } from "react";
import { SelectOption } from "../../../types/SelectOption";
import './ActivityCard.scss';

interface Props {
    title?: string,
    subtitle?: string,
    logo?: string,
    options: SelectOption[],
    selectedValue?: string,
    onOptionSelected: (id: string) => void
}

export const ActivityCard: React.FC<Props> = ({title, subtitle, logo, options, selectedValue, onOptionSelected}) => {   
    return <div className="activity-card">
        <div className='header'>
            <div className='img'>
                <img src={logo} alt='' />
            </div>
            <div className='text'>
                <p className="font-bold text-large">{title}</p>
                <p className="text-xs subtitle">{subtitle}</p>
            </div>
        </div>
        <div className="options">
            {options.map(option =>
                <div className={`option ${option.value === selectedValue ? 'selected' : ''}`} key={option.value} onClick={() => onOptionSelected(option.value)}>
                    <p className='font-bold text-xs'>{option.label}</p>
                </div>
            )}
        </div>
    </div>
};
