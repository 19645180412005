import apiClient, { buildRequestConfig } from "./apiClient";

export const getRecipes = async (): Promise<any> => {
    const config = buildRequestConfig();
    return await apiClient.get('/recipes', config)
        .then((response) => {
            const responseData = response.data;
            return responseData;
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

interface AddRecipeParams {
    name: string, 
    image?: File, 
    categoryId: string,
    difficult: string, 
    preparationTime: string, 
    method: string, 
    ingredients: any
}

export const addRecipe = async ({name, image, categoryId, difficult, preparationTime, method, ingredients}: AddRecipeParams): Promise<any> => {
    const config = buildRequestConfig();
    var formData = new FormData();
    formData.append('name', name);
    formData.append('category_id', categoryId);
    formData.append('difficult', difficult);
    formData.append('preparation_time', preparationTime);
    formData.append('method', method);
    formData.append('ingredients', JSON.stringify(ingredients));
    if(image) {
        formData.append('image', image);
    }
    
    return await apiClient.post(
        '/recipes',
        formData,
        config
    )
    .then((response) => {
        const responseData = response.data;
        return responseData;
    })
    .catch(error => {
        if(error.response && error.response.data.message) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Qualcosa è andato storto. Riprova');
    });
}

interface UpdateRecipeParams {
    id: string, 
    name?: string, 
    image?: File, 
    categoryId?: string,
    difficult?: string, 
    preparationTime?: string, 
    method?: string, 
    ingredients: any
}

export const updateRecipe = async ({id, name, image, categoryId, difficult, preparationTime, method, ingredients}: UpdateRecipeParams): Promise<any> => {
    const config = buildRequestConfig();
    var formData = new FormData();
    if(name) formData.append('name', name);
    if(categoryId) formData.append('category_id', categoryId);
    if(difficult) formData.append('difficult', difficult);
    if(preparationTime) formData.append('preparation_time', preparationTime);
    if(method) formData.append('method', method);
    formData.append('ingredients', JSON.stringify(ingredients));
    if(image) {
        formData.append('image', image);
    }

    return await apiClient.post(
        '/recipes/'+id,
        formData,
        config
    )
    .then((response) => {
        const responseData = response.data;
        return responseData;
    })
    .catch(error => {
        if(error.response && error.response.data.message) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Qualcosa è andato storto. Riprova');
    });
}

export const deleteRecipe = async (recipeId: string): Promise<any> => {
    const config = buildRequestConfig();
    return await apiClient.delete('/recipes/'+recipeId, config)
    .then((response) => {
        const responseData = response.data;
        return responseData;
    })
    .catch(error => {
        if(error.response && error.response.data.message) {
            throw new Error(error.response.data.message);
        }
        throw new Error('Qualcosa è andato storto. Riprova');
    });
}