import './FoodRow.scss';

interface Props {
    id: string,
    name: string,
    quantity: string,
    onFoodDelete: () => void,
}

export const FoodRow: React.FC<Props> = ({name, quantity, onFoodDelete}) => {

    return <div className='food-row'>
        <p className='food-name text-small'>{name}</p>
        <p className='good-quantity text-small'>{quantity}</p>
        <div className='action'>
            <div className='delete-food-button' onClick={onFoodDelete}>
                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z" fill="#FE8D8D"/>
                    <path d="M21.7523 10.1339C22.2405 9.64573 22.2405 8.85427 21.7523 8.36612C21.2642 7.87796 20.4727 7.87796 19.9846 8.36612L14.9954 13.3553L10.1339 8.49378C9.64573 8.00563 8.85427 8.00563 8.36612 8.49378C7.87796 8.98194 7.87796 9.7734 8.36612 10.2616L13.2276 15.1231L8.70744 19.6433C8.21929 20.1314 8.21929 20.9229 8.70744 21.411C9.1956 21.8992 9.98706 21.8992 10.4752 21.411L14.9954 16.8908L19.6433 21.5387C20.1314 22.0268 20.9229 22.0268 21.411 21.5387C21.8992 21.0505 21.8992 20.2591 21.411 19.7709L16.7632 15.1231L21.7523 10.1339Z" fill="white"/>
                </svg>
            </div>
        </div>
    </div>
};
