interface Props {
    className?: string
    isActive?: boolean
    children?: any
    onClick?: () => void
}

export const Cell: React.FC<Props> = ({className='', isActive=false, children, onClick}: Props) => {
    return <div className={`cell ${className}` + (isActive ? 'active' : '')} onClick={onClick}>
        {children}
    </div>
};