import { Bounce, toast } from "react-toastify";
import { SelectOption } from "../types/SelectOption";

export const getOptionFromValue = (options: SelectOption[], value: any): SelectOption => {
    const emptyOption = {label: "", value: ""}
    if (!value) {
        return emptyOption;
    }

    let selectedOption: SelectOption | undefined;
    selectedOption = options.find(option => option.value === value);

    if(!selectedOption) return emptyOption;

    return selectedOption;
}

export const notifyErrorMessage = (message: string) => toast.error(message, {toastId: 'errorMessage'});

export const notifySuccessMessage = (message: string) => toast.success(
    message, 
    {
        toastId: 'successMessage'
        // position: "top-right",
        // autoClose: 5000,
        // hideProgressBar: false,
        // closeOnClick: true,
        // pauseOnHover: true,
        // draggable: true,
        // progress: undefined,
        // theme: "light",
        // transition: Bounce,
    }
);