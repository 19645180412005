import React, { useState, InputHTMLAttributes } from "react";

import { FieldError, FieldErrorsImpl, Merge, UseFormRegister } from 'react-hook-form';

import { Input } from "./Input";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string,
    className?: string,
    placeholder?: string,
    label: string,
    register?: UseFormRegister<any>,
    onChange?: (e: any) => void,
    value?: string,
    error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>
}

export const InputPassword: React.FC<Props> = ({name, label, className, placeholder, value, register, onChange, error}) => {
    const [passwordShown, setPasswordShown] = useState(false);

    return <Input
        name={name}
        className={'password-input ' + (className ? className : '')}
        label={label}
        register={register}
        onChange={onChange}
        value={value}
        error={error}
        placeholder={placeholder}
        type={passwordShown ? 'text' : 'password'}
    >
        <span className='show-password text-xs'>
            <i onClick={() => setPasswordShown(!passwordShown)} className={passwordShown ?  "fa-solid fa-lock-open" : "fa-solid fa-lock" } id="eye"></i>
        </span>
    </Input>

};
