import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getUserToken } from "./TokenService";

const url = 'https://apifunv.danieletotaro.com/api';
// const url = 'http://localhost:81/api';

const defaultConfig: AxiosRequestConfig = {
    baseURL: url,
};

export const buildRequestConfig = (): AxiosRequestConfig => {
    const bearerToken = getUserToken();
    let requestConfig: AxiosRequestConfig = {
        headers: {
            Accept: "application/json",
            'content-type': 'application/json'
            //'content-type': 'multipart/form-data'
        }
    };
    if(bearerToken != '') {
        requestConfig.headers!.Authorization = 'Bearer ' + bearerToken;
    }
    return requestConfig;
};

const apiClient: AxiosInstance =  axios.create(defaultConfig);

export default apiClient;