import { useState } from 'react';
import { add, format, sub } from 'date-fns';

import { ModalCalendar } from '../ModalCalendar/ModalCalendar';
import './HorizontalCalendar.scss';

interface Props {
    date?: Date
    onChange: (value: Date) => void
}

export const HorizontalCalendar: React.FC<Props> = ({date = new Date(), onChange}) => {
    const prevDay = () => onChange && onChange(sub(date, {days: 1}));
    const nextDay = () => onChange && onChange(add(date, {days: 1}));
    const [isModalCalendarOpen, setIsModalCalendarOpen] = useState(false);

    return <>
        <div className="horizontal-calendar">
            <div className="arrow-wrapper" onClick={prevDay}>
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.00009 16.9201L2.48009 10.4001C1.71009 9.63008 1.71009 8.37008 2.48009 7.60008L9.00009 1.08008" stroke="#666666" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className="day" onClick={() => setIsModalCalendarOpen(true)}>
                <p className='text-medium font-bold'>{format(date, "dd LLLL yyyy")}</p>
            </div>
            <div className="arrow-wrapper" onClick={nextDay}>
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.00016 0.999966L7.52016 7.51997C8.29016 8.28997 8.29016 9.54997 7.52016 10.32L1.00016 16.84" stroke="#666666" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
        <ModalCalendar 
            isOpen={isModalCalendarOpen}
            selectedDate={date}
            onDateChange={(date) => onChange(date)}
            onCloseButtonClick={() => setIsModalCalendarOpen(false)}
        />
    </>
};
