import { useEffect, useState } from 'react';
import { format, setDefaultOptions } from 'date-fns';
import { it } from 'date-fns/locale'

import { Calendar } from '../components/Calendar/Calendar';

function DashboardCalendarPage() {
    setDefaultOptions({ locale: it })
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
    }, [])

    return <div className="dashboard-calendar-page">
        <div>
            Selected date: {format(currentDate, "dd MMMM yyyy")}
        </div>
        <Calendar 
            selectedDate={currentDate}
            onChange={setCurrentDate}
        />
    </ div>
}

export default DashboardCalendarPage;