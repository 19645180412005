import { add, differenceInDays, endOfMonth, format, setDate, startOfMonth, sub } from 'date-fns';

import { Cell } from './Cell';
import './Calendar.scss';

const daysOfWeek = ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"];

interface Props {
    selectedDate?: Date
    onDateSelect?: (value: Date) => void
    onChange?: (value: Date) => void
}

export const Calendar: React.FC<Props> = ({selectedDate = new Date(), onDateSelect, onChange}: Props) => {
    const startDate = startOfMonth(selectedDate);
    const endDate = endOfMonth(selectedDate);
    const numDays = differenceInDays(endDate, startDate) + 1;
    const prefixDays = startDate.getDay();

    const prevMonth = () => onChange && onChange(sub(selectedDate, {months: 1}));
    const nextMonth = () => onChange && onChange(add(selectedDate, {months: 1}));
    const prevYear = () => onChange && onChange(sub(selectedDate, {years: 1}));
    const nextYear = () => onChange && onChange(add(selectedDate, {years: 1}));

    const onDateClick = (index: number) => {
        const date = setDate(selectedDate, index);
        onDateSelect && onDateSelect(date);
    }

    return <div className="dashboard-calendar-page">
        <div className='calendar'>
            <Cell onClick={prevYear}>{'<<'}</Cell>
            <Cell onClick={prevMonth}>{'<'}</Cell>
            <Cell className='col-span-3'>{format(selectedDate, "LLLL yyyy")}</Cell>
            <Cell onClick={nextMonth}>{'>'}</Cell>
            <Cell onClick={nextYear}>{'>>'}</Cell>
            
            {daysOfWeek.map(day => <Cell key={day}>{day}</Cell>)}

            {Array.from({length: prefixDays}).map((_, index) => <Cell key={index}></Cell>)}

            {Array.from({length: numDays}).map((_, index) => {
                const date = index + 1;
                const isCurrentDate = date === selectedDate.getDate();
                return <Cell onClick={() => onDateClick(date)} key={index} isActive={isCurrentDate}>{date}</Cell>
            })}
        </div>
    </div>
};