import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import "./LoadingOverlay.scss";

interface Props {
    text?: string
}

export const LoadingOverlay = ({text}: Props) => {
    return (
        <div className='loading-overlay'>
            <LoadingSpinner />
            {text &&
                <p className="text text-large font-bold">{text}</p>
            }
        </div>
    );
};