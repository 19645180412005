import { useState } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button } from "../../../components/Button/Button";
import { Input } from "../../../components/Input/Input";
import { InputPassword } from "../../../components/Input/InputPassword";

import { RegistrationForm } from "../../../types/RegistrationForm";


interface Props {
    registrationForm: RegistrationForm,
    updateRegistrationForm: (registrationForm: RegistrationForm) => void,
    submitButtonClick: () => void
}

export const SignupForm: React.FC<Props> = ({registrationForm, updateRegistrationForm, submitButtonClick}: Props) => {
    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Il nome è obbligatorio'),
        lastname: Yup.string().required('Il cognome è obbligatorio'),
        email: Yup.string().required('L\'email è obbligatoria').email('Email non valida'),
        password: Yup.string()
            .required('La password è obbligatoria')
            .min(6, 'La password deve contenere almeno 6 caratteri'),
            // .max(40, 'La password non può contenere più di 40 caratteri')
            // .matches(/^(?=.*[A-Z])/, 'La password deve contenere almeno una lettera maiuscola')
            // .matches(/^(?=.*[0-9])/, 'La password deve contenere almeno un numero')
            // .matches(/^(?=.*[!@#%&$])/, 'La password deve contenere almeno un carattere speciale (!@#%&$)'),
        confirmPassword: Yup.string()
            .required('Campo obbligatorio')
            .oneOf([Yup.ref('password')], 'Le password non coincidono'),
        privacy: Yup.bool().oneOf([true], 'Devi accettare la privacy policy per proseguire'),        
        informativa: Yup.bool().oneOf([true], 'Devi accettare l\'informativa per proseguire')        
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors
    } = useForm<any>({
        resolver: yupResolver(validationSchema)
    });

    return <div id='signup-form' className="signup-form">
        <div className='form-inputs'>
            <Input
                label="Nome*"
                name="firstname"
                register={register}
                placeholder='Inserisci il tuo nome'
                type="text"
                value={registrationForm.firstname}
                error={errors.firstname?.message}
                onChange={(e) => updateRegistrationForm({firstname: e.target.value})}
            />
            <Input
                label="Cognome*"
                name="lastname"
                register={register}
                placeholder='Inserisci il tuo cognome'
                type="text"
                value={registrationForm.lastname}
                error={errors.lastname?.message}
                onChange={(e) => updateRegistrationForm({lastname: e.target.value})}
            />
            <Input
                label="Email*"
                name="email"
                className="email"
                register={register}
                placeholder='Inserisci la tua email'
                type="text"
                value={registrationForm.email}
                error={errors.email?.message}
                onChange={(e) => updateRegistrationForm({email: e.target.value})}
            />
            <InputPassword
                label='Password*'
                name='password'
                register={register}
                value={registrationForm.password}
                error={errors.password?.message}
                onChange={(e) => updateRegistrationForm({password: e.target.value})}
            />
            <InputPassword
                label='Conferma password*'
                name='confirmPassword'
                register={register}
                value={registrationForm.confirmPassword}
                error={errors.confirmPassword?.message}
                onChange={(e) => updateRegistrationForm({confirmPassword: e.target.value})}
            />
            <Input
                name="informativa"
                type="checkbox"
                register={register}
                checked={registrationForm.informativa}
                error={errors.informativa?.message}
                onChange={(e) => updateRegistrationForm({informativa: e.currentTarget.checked})}
            >
                <p>Ho preso visione del <a href="/files/Foglio Informativo Genitori e Tutori.pdf" target="_blank" className="text-underline">Foglio Informativo Genitori e Tutori</a> per la piattaforma FunVegetables</p>
            </Input>
            <Input
                name="privacy"
                type="checkbox"
                register={register}
                checked={registrationForm.privacy}
                error={errors.privacy?.message}
                onChange={(e) => updateRegistrationForm({privacy: e.currentTarget.checked})}
            >
                <p>Accetto la <a href="/files/Privacy Policy Adulti e Minori.pdf" target="_blank" className="text-underline">Privacy Policy Adulti e Minori</a> per la piattaforma FunVegetables</p>
            </Input>
        </div>
        <div className='button-wrapper'>
            <Button 
                type="button"
                label="Registrati"
                className="primary-button"
                onClick={handleSubmit(submitButtonClick)}
            />
        </div>
    </div>
};
