import apiClient, { buildRequestConfig } from "./apiClient";

import { getUserToken, removeUserToken, setUserToken } from "../services/TokenService";
import { User } from "../types/User";
import { removeLocalUser } from "./UserService";

export const login = async (email: string, password: string): Promise<String> => {
    const requestData = {
        email: email,
        password: password,
    };

    return await apiClient.post('/auth/dashboard-login', requestData)
        .then((response) => {
            const responseData = response.data;
            setUserToken(responseData);
            return responseData;
        })
        .catch(error => {
            if (error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        });
};

export const logout = () => {
    removeUserToken();
    removeLocalUser();
    window.location.href = '/accedi';
}

export const getUser = async (): Promise<User> => {
    const config = buildRequestConfig();
    return await apiClient.get('/user', config)
        .then((response) => {
            const responseData = response.data;
            return responseData;
        })
        .catch(error => {
            if (error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        });
};

export const isLoggedIn = async (): Promise<boolean> => {
    let logged = false;
    return new Promise(resolve => {
        if (getUserToken() != undefined) {
            logged = true;
        } else {
            logged = false;
        }
        resolve(logged);
    });
};

export const sendPasswordResetLink = async (email: string): Promise<{message: string}> => {
    const requestData = {
        email: email,
    };

    return await apiClient.post('/password-reset-link', requestData)
        .then((response) => {
            const responseData = response.data;
            return responseData;
        })
        .catch(error => {
            if (error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        });
};

export const resetPassword = async (password: string, email: string | null, token: string | null): Promise<{message: string}> => {
    if(!email || ! token) {
        throw new Error('Non siamo riusciti a resettare la password. Riprova più tardi');
    }

    const requestData = {
        token,
        email,
        password,
        password_confirmation: password,
    }

    return await apiClient.post('/password-reset', requestData)
        .then((response) => {
            console.log(response);
            const responseData = response.data;
            return responseData;
        })
        .catch(error => {
            console.log(error);
            if (error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        });
};