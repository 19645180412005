import { InputHTMLAttributes } from "react";
import { FieldError, FieldErrorsImpl, Merge, UseFormRegister } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import { IMaskInput } from 'react-imask';
import IMask from "imask";

import "./Input.scss";

interface ErrorProps {
    error: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>,
}

interface Props extends InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement>{
    checked?: boolean,
    className?: string,
    disabled?: boolean,
    error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>,
    label?: string,
    name: string,
    placeholer?: string,
    suffix?: string,
    type: string,
    value?: string,
    onChange?: (e: any) => void,
    register?: UseFormRegister<any>,
}

export const Input: React.FC<Props> = ({
    name, 
    label, 
    placeholder, 
    suffix, 
    type, 
    className, 
    value, 
    checked,
    disabled, 
    register, 
    onChange, 
    error, 
    children
}) => {

    switch(type) {
        case 'checkbox':
            return <InputWrapper type="checkbox" className={className} error={error}>
                <label htmlFor={name} className="label">
                    {children}
                    <input type="checkbox" name={name} checked={checked} onChange={onChange} {...register?.(name, {
                        onChange: onChange
                    })}/>
                    <span className="checkmark"></span>
                </label>
            </InputWrapper>

        case 'radio':
            return <InputWrapper type="radio" className={className} error={error}>
                <label htmlFor={name} className="label">
                    {children}
                    <input type="radio" name={name} checked={checked} onChange={onChange} {...register?.(name, {
                        onChange: onChange
                    })}/>
                    <span className="checkmark"></span>
                </label>
            </InputWrapper>

        case 'select':
            return <InputWrapper type="select" className={className} error={error}>
                <label htmlFor={name} className="label">
                    <span>{label}</span>
                </label>
                {children}
            </InputWrapper>

        case 'textarea':
            return <InputWrapper className={className} error={error}>
                <label htmlFor={name}>{label}</label>
                <textarea rows={4} name={name} placeholder={placeholder} value={value} onChange={onChange} {...register?.(name, {onChange: onChange})}></textarea>
                {children}
            </InputWrapper>  
            
        case 'wysiwyg':
            return <InputWrapper className={className} error={error}>
                <label htmlFor={name}>{label}</label>
                <Editor
                    apiKey="00pj98810ndr05vceh3sayb13b0dkcb3j9taze1polb0tcuj"
                    value={value}
                    init={{
                        skin: "snow",
                        icons: "thin",
                        placeholder: "Ask a question or post an update...",
                        height: 400,
                        menubar: true,
                        plugins: [],
                        textcolor_rows: "4",
                        toolbar: "undo redo | styleselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent "
                    }}
                    onEditorChange={onChange}
                />
            </InputWrapper>  

        case 'date':
            return <div className={'input-wrapper ' + (className ? className : '') + (error ? 'has-error' : '')}>
                <label htmlFor={name}>{label}</label>
                <IMaskInput
                    mask={"DD/MM/YYYY"}
                    blocks={{
                        DD: {
                            mask: IMask.MaskedRange,
                            from: 1,
                            to: 31
                        },
                        MM: {
                            mask: IMask.MaskedRange,
                            from: 1,
                            to: 12
                        },
                        YYYY: {
                            mask: IMask.MaskedRange,
                            from: 1900,
                            to: new Date().getFullYear()
                        }
                    }}
                    unmask={false}
                    name={name}
                    disabled={disabled}
                    placeholder="DD/MM/YYYY"
                    value={value}
                    onChange={onChange}
                    {...register?.(name, {
                        onChange: onChange
                    })}
                />
                {children}
                {error && 
                    <InputError error={error}/>
                }
            </div>

        case 'number':
            return <InputWrapper className={className} error={error}>
                <label htmlFor={name}>{label}</label>
                <div className="input">
                    <input type={type} name={name} min={0} max={10} placeholder={placeholder} disabled={disabled} value={value} onChange={onChange} {...register?.(name, {
                        onChange: onChange
                    })}/>
                    {suffix &&
                        <span className="text-xs suffix">{suffix}</span>
                    }
                </div>
            </InputWrapper>

        default:
            return <InputWrapper className={className} error={error}>
                <label htmlFor={name}>{label}</label>
                <div className="input">
                    <input type={type} name={name} placeholder={placeholder} disabled={disabled} value={value} onChange={onChange} {...register?.(name, {
                        onChange: onChange
                    })}/>
                    {suffix &&
                        <span className="text-xs suffix">{suffix}</span>
                    }
                </div>
                {children}
            </InputWrapper>
    }

};

export const InputWrapper: React.FC<{type?: string, className?: string, error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>, children?: any}> = ({
    type,
    className, 
    error, 
    children
}) => {

    return <div className={'input-wrapper ' + (type ? type : '') + (className ? className : '') + (error ? ' has-error' : '')}>
        {children}
        {error && 
            <InputError error={error}/>
        }
    </div>
};

export const InputError: React.FC<ErrorProps> = ({error}) => {
    return <div className="input-error">
        <span>{error.toString()}</span>
    </div>
}

