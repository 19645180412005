import { Button } from "../../../components/Button/Button";

import { Recipe } from "../../../types/Recipe";

import './RecipeCard.scss';

interface Props {
    recipe: Recipe
}

export const RecipeCard: React.FC<Props> = ({recipe}: Props) => {

    return <div className='recipe-card'>
        <div className="recipe-image">
            <img src="https://placehold.co/500x300" />
        </div>
        <div className="recipe-content">
            <p className="recipe-title font-bold text-large">{recipe.name}</p>
            {recipe.method &&
                <p className="recipe-description">{recipe.method}</p>
            }
            <Button 
                className="primary-button"
                label="Leggi la Ricetta"
            />
        </div>
    </div>
};
