import { useEffect, useState } from 'react';

import { ContestCardsSkeleton } from '../../components/Skeletons/ContestCardsSkeleton';

import { getContests } from '../../services/ContestService';

import { Contest } from '../../types/Contest';

import { ContestCard } from './ContestCard/ContestCard';
import './DashboardContestPage.scss';

function DashboardContestPage() {
    const [isLoadingContests, setIsLoadingContests] = useState<boolean>(true);
    const [contests, setContests] = useState<Contest[]>([]);

    useEffect(() => {
        fetchContests();
    }, [])

    const fetchContests = async () => {
        setIsLoadingContests(true);
        await getContests()
            .then(contest => {
                setContests(contest);
            })
            .catch(error => {})
            .finally(() => {
                setIsLoadingContests(false);
            });
    };

    return <div className="dashboard-contests-page">
        <div className='hero'>
            <h1 className='title'>Contest</h1>
        </div>
        <div className='content'>
            {/* <p>Partecipa ai contest</p> */}
            {isLoadingContests ?
                <ContestCardsSkeleton /> :
                <div className='contests'>
                    {contests.map(contest =>
                        <ContestCard
                            contest={contest}
                        />
                    )}
                </div>
            }
        </div>
    </ div>
}

export default DashboardContestPage;