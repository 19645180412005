import { Meal } from "../types/Meal";
import apiClient, { buildRequestConfig } from "./apiClient";

export const updateMeal = async (updatedMeal: Meal): Promise<any> => {
    const config = buildRequestConfig();
    const reuqestUrl = `/meals/${updatedMeal.id}`;
    return await apiClient.put(reuqestUrl, updatedMeal, config)
        .then((response) => {
            if(response.status === 200) {
                const responseData = response.data;
                return responseData;
            } else {
                throw new Error('Qualcosa è andato storto. Riprova');
            }
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}

export const deleteMealFood = async (foodId: string): Promise<any> => {
    const config = buildRequestConfig();
    const reuqestUrl = `/meal-foods/${foodId}`;
    return await apiClient.delete(reuqestUrl, config)
        .then((response) => {
            if(response.status === 204) {
                const responseData = response.data;
                return responseData;
            } else {
                throw new Error('Qualcosa è andato storto. Riprova');
            }
        })
        .catch(error => {
            console.log(error);
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}