import { useState } from 'react';
import { setDefaultOptions } from 'date-fns';
import { it } from 'date-fns/locale'

import { Calendar } from '../../../components/Calendar/Calendar';
import { Modal } from '../../../components/Modal/Modal';

interface Props {
    isOpen: boolean
    selectedDate?: Date
    onDateChange: (value: Date) => void
    onCloseButtonClick?: () => void
}

export const ModalCalendar: React.FC<Props> = ({isOpen, selectedDate, onDateChange, onCloseButtonClick}) => {
    setDefaultOptions({ locale: it })

    return <Modal
        className='mini'
        isOpen={isOpen}
        onCloseButtonClick={onCloseButtonClick}
    >
        <Calendar
            selectedDate={selectedDate}
            onDateSelect={(date) => {
                onDateChange(date);
                onCloseButtonClick?.();
            }}
            onChange={(date) => onDateChange(date)}
        />
    </Modal>
};
