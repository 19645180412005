import React from 'react';
import './PageLoader.scss';

const PageLoader = () => {
  return (
    <div className='page-loader'>
        <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
        </div>
    </div>
  );
};

export default PageLoader;