import "./ErrorMessage.scss";

interface Props {
    className?: string, 
    message: string,
}

export const ErrorMessage: React.FC<Props> = ({className, message}: Props) => {

    return <div className={"error-message-wrapper " + (className ? className : '')}>
        <div className="error-message">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 5C11.5523 5 12 5.44772 12 6V11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11V6C10 5.44772 10.4477 5 11 5ZM11 14C11.5523 14 12 14.4477 12 15V15.5C12 16.0523 11.5523 16.5 11 16.5C10.4477 16.5 10 16.0523 10 15.5V15C10 14.4477 10.4477 14 11 14Z" fill="#AD1F44"></path></svg>
            <span className="text-xs message" >{message}</span>
        </div>
    </div>
};
