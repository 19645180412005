import { EmojiOption } from "../types/SelectOption";

// export const imageBaseUrl = 'https://apifunv.danieletotaro.com/images/'
export const imageBaseUrl = 'http://localhost:81/images/'

export const genders = [
    {
        label: 'Femminile',
        value: 'F',
    },
    {
        label: 'Maschile',
        value: 'M',
    }
];

export const recipeCategories = [
    {
        label: 'Antipasti',
        value: '1',
    },
    {
        label: 'Dolci',
        value: '4',
    },
    {
        label: 'Primi Piatti',
        value: '2',
    },
    {
        label: 'Secondi Piatti',
        value: '3',
    }
];

export const recipeDifficults = [
    {
        label: 'Facile',
        value: '1',
    },
    {
        label: 'Media',
        value: '2',
    },
    {
        label: 'Difficile',
        value: '3',
    }
];

export const waterValues = [
    {
        label: '< 1 litro',
        value: '1',
    },
    {
        label: 'Tra 1 e 2 litri',
        value: '2',
    },
    {
        label: '> 2 litri',
        value: '3',
    }
];

export const trainingValues = [
    {
        label: '< 30 min',
        value: '1',
    },
    {
        label: 'Tra 30 e 60 min',
        value: '2',
    },
    {
        label: '> 60 minuti',
        value: '3',
    }
];

export const emojiValues: EmojiOption[] = [
    {
        imagePath: 'sad.png',
        value: 1,
    },
    {
        imagePath: 'confused.png',
        value: 2,
    },
    {
        imagePath: 'smile.png',
        value: 3,
    }
];
