import './ProfileCard.scss';

interface Props {
    children: any
    className?: string
    title: string
}

export const ProfileCard: React.FC<Props> = ({children, className, title}) => {
    return <div className={'profile-card ' + (className ? className : '')}>
        <div className='header'>
            <div className='card-title'>
                <p className='text-large font-bold'>{title}</p>
            </div>
        </div>
        <div className='body'>
            {children}
        </div>
    </div>
};
