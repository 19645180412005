import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";

import './DashboardPageLayout.scss';

function DashboardPageLayout() {
    return (  
        <div className="dashboard">
            {/* <Navbar /> */}
            <Sidebar />
            <div className='main-content'>
                <div className="page-content">
                    <Outlet />
                </div>
            </div>
            <ToastContainer limit={1}></ToastContainer>
        </ div>
    );
}

export default DashboardPageLayout;