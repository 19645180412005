import { EmojiOption } from "../../types/SelectOption";
import { InputError } from "./Input";

interface Props {
    label: string,
    error?: string,
    value?: number,
    description: string,
    options: EmojiOption[]
    onSelect: (option: EmojiOption) => void
}

export const InputEmojiSelect: React.FC<Props> = ({
    label,
    error,
    value,
    description,
    options,
    onSelect 
}) => {

    return <div className='input-emoji-select'>
        <p className='label text-xs font-bold'>{label}</p>
        <p className='description text-xs'>{description}</p>
        <div className='options'>
            {options.map((option, i) =>
                <div key={i} className={`option ${option.value === value ? 'selected' : ''}`} onClick={() => onSelect(option)}>
                    <img src={`images/${option.imagePath}`} />
                    <span className="dot"></span>
                </div>
            )}
        </div>
        {error && 
            <InputError error={error}/>
        }
    </div>

};
