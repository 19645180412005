import { useEffect, useState } from "react";
import { format, setDefaultOptions } from "date-fns";
import { it } from 'date-fns/locale'

import { MealCardsSkeleton } from "../../components/Skeletons/MealCardsSkeleton";

import { getMealData } from "../../helpers/mealHelpers";
import { notifyErrorMessage, notifySuccessMessage } from "../../helpers/commonHelpers";

import BreakfastLogo from "../../images/breakfast.png";
import LunchLogo from "../../images/lunch.png";
import DinnerLogo from "../../images/dinner.png";
import SnackLogo from "../../images/snack.png";
import MerendaLogo from "../../images/merenda.png";
import DrinkWaterLogo from "../../images/drink-water.png";
import TrainingLogo from "../../images/training.png";

import { addUserTraining, addUserWater, getLocalUser, getUserMeals, getUserTraining, getUserWater } from "../../services/UserService";

import { User } from "../../types/User";
import { Meal } from "../../types/Meal";
import { Water } from "../../types/Water";
import { Training } from "../../types/Training";

import { trainingValues, waterValues } from "../../utils/Constants";

import { HorizontalCalendar } from "./HorizontalCalendar/HorizontalCalendar";
import { MealCard } from "./MealCard/MealCard";
import { ActivityCard } from "./ActivityCard/ActivityCard";
import './DashboardFoodDiaryPage.scss';
import { logout } from "../../services/AuthService";

function DashboardFoodDiaryPage() {
    setDefaultOptions({ locale: it })
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isLoadingMeals, setIsLoadingMeals] = useState(false);
    const [isRefreshMeals, setIsRefreshMeals] = useState(false);
    const [breakfast, setBreakfast] = useState<Meal>();
    const [lunch, setLunch] = useState<Meal>();
    const [dinner, setDinner] = useState<Meal>();
    const [spuntino, setSpuntino] = useState<Meal>();
    const [merenda, setMerenda] = useState<Meal>();
    const [training, setTraining] = useState<string>();
    const [water, setWater] = useState<string>();
    const localUser: User = getLocalUser();

    useEffect(() => {
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        fetchUserMeals(formattedDate);
        fetchUserWater(formattedDate);
        fetchUserTraining(formattedDate);
    }, [selectedDate, isRefreshMeals])

    const fetchUserMeals = async (date: string) => {
        setIsLoadingMeals(true);
        if(localUser && localUser.id) {
            await getUserMeals(localUser.id, date)
                .then(userMeals => {
                    const breakfast = getMealData(userMeals, "Colazione");
                    const lunch = getMealData(userMeals, "Pranzo");
                    const dinner = getMealData(userMeals, "Cena");
                    const spuntino = getMealData(userMeals, "Spuntino");
                    const merenda = getMealData(userMeals, "Merenda");
                    setBreakfast(breakfast);
                    setLunch(lunch);
                    setDinner(dinner);
                    setSpuntino(spuntino);
                    setMerenda(merenda);
                })
                .catch(error => {})
                .finally(() => {
                    setIsLoadingMeals(false);
                });
        } else {
            logout();
        }
    };

    const fetchUserTraining = async (date: string) => {
        if(localUser && localUser.id) {
            await getUserTraining(localUser.id, date)
                .then(training => {
                    if(training) {
                        setTraining(training.value)
                    } else {
                        setTraining(undefined);
                    }
                })
                .catch(error => console.log(error))
        }
    };

    const updateUserTraining = async (value: string) => {
        const trainingObj: Training = {
            date: format(selectedDate, 'yyyy-MM-dd'),
            value
        };
        if(localUser && localUser.id) {
            addUserTraining(localUser.id, trainingObj)
                .then(response => {
                    setTraining(response.value)
                    // notifySuccessMessage('Il tuo Diario Alimentare è stato aggiornato correttamente!');
                })
                .catch(error => {
                    console.log(error);
                    notifyErrorMessage(error.message);
                });
        }
    };

    const fetchUserWater = async (date: string) => {
        if(localUser && localUser.id) {
            await getUserWater(localUser.id, date)
                .then(water => {
                    if(water) {
                        setWater(water.value)
                    } else {
                        setWater(undefined);
                    }
                })
                .catch(error => console.log(error))
        }
    };

    const updateUserWater = async (value: string) => {
        const waterObj: Water = {
            date: format(selectedDate, 'yyyy-MM-dd'),
            value
        };
        if(localUser && localUser.id) {
            addUserWater(localUser.id, waterObj)
                .then(response => {
                    setWater(response.value)
                    // notifySuccessMessage('Il tuo Diario Alimentare è stato aggiornato correttamente!');
                })
                .catch(error => {
                    console.log(error);
                    notifyErrorMessage(error.message);
                });
        }
    };

    return <div className="dashboard-food-diary-page">
        <div className='hero'>
            <h1 className='title'>Diario Alimentare</h1>
        </div>
        <div className='content'>
            <HorizontalCalendar 
                date={selectedDate}
                onChange={setSelectedDate}
            />
            {isLoadingMeals ?
                <MealCardsSkeleton /> :
                <div className="food-diary-wrapper">
                    <div className="general-cards-wrapper">
                        <ActivityCard 
                            title="Acqua Bevuta"
                            subtitle="Seleziona la quantità di acqua che hai bevuto nella data selezionata"
                            options={waterValues}
                            selectedValue={water}
                            logo={DrinkWaterLogo}
                            onOptionSelected={(id) => updateUserWater(id)}
                        />
                        <ActivityCard 
                            title="Attività Fisica Svolta"
                            subtitle="Indica quanta attività fisica hai svolto nella data selezionata"
                            options={trainingValues}
                            selectedValue={training}
                            logo={TrainingLogo}
                            onOptionSelected={(id) => updateUserTraining(id)}
                        />
                    </div>
                    <div className="meals-wrapper">
                        <MealCard 
                            name="Colazione"
                            meal={breakfast}
                            date={selectedDate}
                            logo={BreakfastLogo}
                            onMealUpdated={(mealUpdated) => setBreakfast(mealUpdated)}
                        />
                        <MealCard 
                            name="Spuntino"
                            meal={spuntino}
                            date={selectedDate}
                            logo={SnackLogo}
                            onMealUpdated={(mealUpdated) => setSpuntino(mealUpdated)}
                        />
                        <MealCard 
                            name="Pranzo"
                            meal={lunch}
                            date={selectedDate}
                            logo={LunchLogo}
                            onMealUpdated={(mealUpdated) => setLunch(mealUpdated)}
                        />
                        <MealCard 
                            name="Merenda"
                            meal={merenda}
                            date={selectedDate}
                            logo={MerendaLogo}
                            onMealUpdated={() => setIsRefreshMeals(!isRefreshMeals)}
                        />
                        <MealCard 
                            name="Cena"
                            meal={dinner}
                            date={selectedDate}
                            logo={DinnerLogo}
                            onMealUpdated={() => setIsRefreshMeals(!isRefreshMeals)}
                        />
                    </div>
                </div>
            }
        </div>
    </ div>
}

export default DashboardFoodDiaryPage;