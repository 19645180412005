import { InputError } from "./Input";

interface Props {
    label: string,
    error?: string,
    value?: number,
    description: string,
    options: number[]
    onSelect: (option: number) => void
}

export const InputScoreSelect: React.FC<Props> = ({
    label,
    error,
    value,
    description,
    options,
    onSelect 
}) => {

    return <div className='input-score-select'>
        <p className='label text-xs font-bold'>{label}</p>
        <p className='description text-xs'>{description}</p>
        <div className='options'>
            {options.map((option, i) =>
                <div key={i} className={`option ${option === value ? 'selected' : ''}`} onClick={() => onSelect(option)}>
                    <p className='text-xs'>{option}</p>
                </div>
            )}
        </div>
        {error && 
            <InputError error={error}/>
        }
    </div>

};
