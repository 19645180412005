import apiClient, { buildRequestConfig } from "./apiClient";

export const getContests = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const dummyData = [
                {
                    id: 1,
                    name: 'Miglior Chef',
                    description: 'Descrizione contest Miglior Chef'
                },
                {
                    id: 2,
                    name: 'Miglior Ricetta',
                    description: 'Descrizione contest Miglior Ricetta'
                }
            ];

            // Resolve the promise with the dummy data
            resolve(dummyData);

            // You can also simulate an error condition if needed
            // reject(new Error('Failed to fetch data'));
        }, 1000);
    });
    const config = buildRequestConfig();
    return await apiClient.get('/contests', config)
        .then((response) => {
            const responseData = response.data;
            return responseData;
        })
        .catch(error => {
            if(error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        }); 
}