import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Input, InputError } from '../../../components/Input/Input';
import { Button } from '../../../components/Button/Button';
import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { TickIcon } from '../../../components/Icons/TickIcon';
import { InputScoreSelect } from '../../../components/Input/InputScoreSelect';
import { InputEmojiSelect } from '../../../components/Input/InputEmojiSelect';

import { notifyErrorMessage, notifySuccessMessage } from '../../../helpers/commonHelpers';

import { ArrowUpCircle } from '../../../icons/ArrowUpCircle';
import { ArrowDownCircle } from '../../../icons/ArrowDownCircle';

import { deleteMealFood, updateMeal } from '../../../services/MealService';
import { addUserMeal, getLocalUser } from '../../../services/UserService';

import { Food } from '../../../types/Food';
import { Meal } from '../../../types/Meal';
import { User } from '../../../types/User';

import { emojiValues } from '../../../utils/Constants';

import { FoodRow } from './FoodsTable/FoodRow';
import { AddFoodModal } from './AddFoodModal/AddFoodModal';
import './MealCard.scss';

interface Props {
    name: string,
    meal?: Meal,
    date: Date,
    logo?: string,
    onMealUpdated?: (meal: Meal) => void
}

export const MealCard: React.FC<Props> = ({name, date, meal, logo, onMealUpdated}) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [isAddFoodModalOpen, setIsAddFoodModalOpen] = useState<boolean>(false);
    const [isUpdatingMeal, setIsUpdatingMeal] = useState<boolean>(false);
    const [time, setTime] = useState<string>(meal?.time ?? '');
    const [place, setPlace] = useState<string>(meal?.place ?? '');
    const [notes, setNotes] = useState<string>(meal?.notes ?? '');
    const [hunger, setHunger] = useState<number>(meal?.hunger ?? 0);
    const [hungerError, setHungerError] = useState<string>('');
    const [satiety, setSatiety] = useState<number>(meal?.satiety ?? 0);
    const [satietyError, setSatietyError] = useState<string>('');
    const [humor, setHumor] = useState<number>(meal?.humor ?? 0);
    const [humorError, setHumorError] = useState<string>('');
    const [foods, setFoods] = useState<Food[]>(meal?.foods || []);
    const [foodsError, setFoodsError] = useState<string>('');

    const validationSchema = Yup.object().shape({
        place: Yup.string().required('Questo campo è obbligatorio'),
        time: Yup.string().required('Questo campo è obbligatorio'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors
    } = useForm<any>({
        resolver: yupResolver(validationSchema)
    });

    const addFood = (name: string, quantity: string) => {
        const newFood = {name: name, quantity: quantity};
        setFoodsError('');
        setFoods([...foods, newFood]);
        setIsAddFoodModalOpen(false);
    }

    const deleteFood = (index: number, id?: string) => {
        if(id) {
            deleteMealFood(id);
        }
        const newFoods = foods.filter((_, i) => i !== index);
        setFoods(newFoods);
    }

    const checkErrors = (): boolean => {
        let hasError = false;
        if(!foods.length) {
            setFoodsError('Devi aggiungere almeno un alimento/una bevanda');
            hasError = true;
        }
        if(!hunger) {
            setHungerError('Devi selezionare almeno un\'opzione');
            hasError = true;
        }
        if(!satiety) {
            setSatietyError('Devi selezionare almeno un\'opzione');
            hasError = true;
        }
        if(!humor) {
            setHumorError('Devi selezionare almeno un\'opzione');
            hasError = true;
        }
        return hasError;
    }

    const saveMeal = () => {
        if(checkErrors()) return;
        setIsUpdatingMeal(true);
        let mealObj: Meal = {
            date: format(date, 'yyyy-MM-dd'),
            foods: foods,
            humor: humor,
            hunger: hunger,
            name: name,
            notes: notes,
            place: place,
            satiety: satiety,
            time: time,
        }
        if(meal && meal.id) {
            mealObj.id = meal.id;
            updateMeal(mealObj)
                .then(meal => {
                    notifySuccessMessage('Il tuo Diario Alimentare è stato aggiornato correttamente!');
                    onMealUpdated?.(meal);
                })
                .catch(error => {
                    notifyErrorMessage(error.message);
                })
                .finally(() => setIsUpdatingMeal(false));
        } else {
            const localUser: User = getLocalUser();
            if(localUser && localUser.id) {
                addUserMeal(localUser.id, mealObj)
                    .then(meal => {
                        notifySuccessMessage('Il tuo Diario Alimentare è stato aggiornato correttamente!');
                        onMealUpdated?.(meal);
                    })
                    .catch(error => {
                        notifyErrorMessage(error.message);
                    })
                    .finally(() => setIsUpdatingMeal(false));
            }
        }
    }

    return <div className={"meal-card" + (isCollapsed ? ' collapsed' : '')}>
        <div className="header" onClick={() => setIsCollapsed(!isCollapsed)}>
            <div className="meal-info">
                <div className="image-wrapper">
                    <img src={logo} alt={`Logo ${name}`} />
                </div>
                <div className={`name ${meal?.id ? 'saved' : ''}`}>
                    <p className="font-bold text-large">{name}</p>
                    {meal?.id && !isCollapsed && <TickIcon />}
                </div>
            </div>
            <div className="arrow-wrapper">
                {isCollapsed ? <ArrowUpCircle /> : <ArrowDownCircle />}
            </div>
        </div>
        {isCollapsed &&
            <>
                <div className='content'>
                    <div className='input-fields'>
                        <Input
                            label="A che ora?*"
                            name="time"
                            placeholder="hh:mm"
                            type="time"
                            register={register}
                            value={time}
                            error={errors.time?.message}
                            onChange={(e) => setTime(e.target.value)}
                        />
                        <Input
                            label="Dove?*"
                            name="place"
                            placeholder="Casa, Scuola..."
                            type="text"
                            register={register}
                            value={place}
                            error={errors.place?.message}
                            onChange={(e) => setPlace(e.target.value)}
                        />
                        <InputScoreSelect 
                            label='Fame*'
                            error={hungerError}
                            description='Quanto avevi fame da 1 a 5 prima di questo pasto?'
                            options={[1,2,3,4,5]}
                            value={hunger}
                            onSelect={(value) => {
                                setHungerError('')
                                setHunger(value)
                            }}
                        />
                        <InputScoreSelect 
                            label='Sazietà*'
                            error={satietyError}
                            description='Quanto ti sei sentito sazio da 1 a 5 dopo questo pasto?'
                            options={[1,2,3,4,5]}
                            value={satiety}
                            onSelect={(value) => {
                                setSatietyError('');
                                setSatiety(value);
                            }}
                        />
                        <InputEmojiSelect 
                            label='Umore*'
                            error={humorError}
                            description='Qual era il tuo umore dopo questo pasto?'
                            options={emojiValues}
                            value={humor}
                            onSelect={(option) => {
                                setHumor(option.value);
                                setHumorError('');
                            }}
                        />
                        <Input
                            label="Note"
                            name="notes"
                            placeholder="Dicci qualcosa in più..."
                            type="textarea"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </div>
                    <div className='separator'></div>
                    <div className='foods-wrapper'>
                        <div className='name'>
                            <p className='text-small font-bold'>Alimenti e Bevande</p>
                        </div>
                        <div className='foods-table'>
                            {foods.length > 0 &&
                                <div className='table-header'>
                                    <p className='text-small font-bold'>Nome</p>
                                    <p className='text-small font-bold'>Quantità</p>
                                </div>
                            }
                            {!foods.length && 
                                <div className='empty-list'>
                                    <p className='text-xs'>Non hai ancora aggiunto nessun alimento/bevanda</p>
                                </div>
                            }
                            {foods.map((food, i) => 
                                <FoodRow 
                                    key={i}
                                    id={food.id ?? ''}
                                    name={food.name ?? ''}
                                    quantity={food.quantity ?? ''}
                                    onFoodDelete={() => deleteFood(i, food.id)}
                                />
                            )}
                        </div>
                        <div className='add-food-button' onClick={() => setIsAddFoodModalOpen(true)}>
                            {/* <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 15C0.5 6.99187 6.99187 0.5 15 0.5C23.0081 0.5 29.5 6.99187 29.5 15C29.5 23.0081 23.0081 29.5 15 29.5C6.99187 29.5 0.5 23.0081 0.5 15Z" stroke="#D9D9D9"/>
                                <path d="M15 9V21M9 15H21" stroke="#D9D9D9"/>
                            </svg> */}
                            <p className='text-xs'>Aggiungi</p>
                        </div>
                        {/* {foodsError &&
                            <InputError
                                error={foodsError}
                            />
                        } */}
                        {foodsError &&
                            <ErrorMessage 
                                message={foodsError}
                            />
                        }
                    </div>
                </div>
                <div className='footer'>
                    <Button
                        type='button'
                        className='primary-button'
                        label='Salva'
                        disabled={isUpdatingMeal}
                        isLoading={isUpdatingMeal}
                        onClick={handleSubmit(saveMeal)}
                    />
                </div>
            </>
        }
        <AddFoodModal 
            isOpen={isAddFoodModalOpen}
            onCloseButtonClick={() => setIsAddFoodModalOpen(false)}
            handleFoodSave={addFood}
        />
    </div>
};
