import { useEffect, useState } from "react";

import { getUser } from "../../services/UserService";

import { RegistrationForm } from "../../types/RegistrationForm";

import { ProfilePersonalDataForm } from "./ProfilePersonalDataForm";
import { ProfileLoginDataForm } from "./ProfileLoginDataForm";
import { ProfileCard } from "./ProfileCard/ProfileCard";
import './DashboardProfilePage.scss';

function DashboardProfilePage() {
    const [isLoadingUser, setIsLoadingUser] = useState<boolean>(true);
    const [user, setUser] = useState<RegistrationForm>({});
    useEffect(() => {
        fetchUser();
    }, [])

    const fetchUser = async () => {
        setIsLoadingUser(true);
        await getUser()
            .then(user => {
                setUser(user);
            })
            .finally(() => {
                setIsLoadingUser(false);
            });
    };

    const updateRegistrationForm = (updatedRegistrationForm: RegistrationForm) => {
        setUser({...user, 
          ...(updatedRegistrationForm.firstname !== undefined && {firstname: updatedRegistrationForm.firstname}),
          ...(updatedRegistrationForm.lastname !== undefined && {lastname: updatedRegistrationForm.lastname}),
          ...(updatedRegistrationForm.gender !== undefined && {gender: updatedRegistrationForm.gender}),
          ...(updatedRegistrationForm.birthdate !== undefined && {birthdate: updatedRegistrationForm.birthdate}),
          ...(updatedRegistrationForm.weight !== undefined && {weight: updatedRegistrationForm.weight}),
          ...(updatedRegistrationForm.height !== undefined && {height: updatedRegistrationForm.height}),
          ...(updatedRegistrationForm.bmi !== undefined && {bmi: updatedRegistrationForm.bmi}),
          ...(updatedRegistrationForm.email !== undefined && {email: updatedRegistrationForm.email}),
          ...(updatedRegistrationForm.password !== undefined && {password: updatedRegistrationForm.password}),
          ...(updatedRegistrationForm.confirmPassword !== undefined && {confirmPassword: updatedRegistrationForm.confirmPassword}),
          ...(updatedRegistrationForm.privacy !== undefined && {privacy: updatedRegistrationForm.privacy}),
        });
    }

    return <div className="dashboard-profile-page">
        <div className='hero'>
            <h1 className='title'>Profilo</h1>
        </div>
        <div className='content'>
            {!isLoadingUser &&
                <>
                    <ProfileCard 
                        title="Dati Personali"
                        className="personal-data-card"
                    >
                        <ProfilePersonalDataForm 
                            registrationForm={user}
                            updateRegistrationForm={updateRegistrationForm}
                        />
                    </ProfileCard>
                    <ProfileCard 
                        title="Aggiorna Password"
                        className="update-password-card"
                    >
                        <ProfileLoginDataForm />
                    </ProfileCard>
                </>
            }
        </div>
    </ div>
}

export default DashboardProfilePage;