import { useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';

import { notifyErrorMessage, notifySuccessMessage } from '../../helpers/commonHelpers';

import Logo from "../../images/logo-fun-vegetables.png";

import * as Auth from "../../services/AuthService";

function PasswordRecoveryPage() {
    const [email, setEmail] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const resetPassword = () => {
        setIsLoading(true);
        setErrorMessage('');
        Auth.sendPasswordResetLink(email)
            .then(response => {
                notifySuccessMessage(`Controlla la tua email ${email}. Ti abbiamo inviato il link per ripristinare la password`);
            })  
            .catch(error => {
                notifyErrorMessage(error.message);
            })
            .finally(() => setIsLoading(false));
    }

    return (  
        <div className="login-page">
            <div className='content-wrapper'>
                <div className="logo-section">
                    <img className='logo-image' src={Logo} alt="Logo Fun Vegetables" />
                </div>
                <div className="form-section">
                    <div className='form-title'>
                        <p className='font-bold title'>Non ricordi la password?</p>
                        <p className='subtitle'>Scrivi qui l'email utilizzata in fase di registrazione. Riceverai un link per resettare la password</p>
                    </div>
                    <div className='form'>
                        <Input
                            label="Email*"
                            name="email"
                            placeholder='Inserisci la tua email'
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {errorMessage &&
                            <div className='error-message'>
                                <p>{errorMessage}</p>
                            </div>
                        }
                        <div className='button-wrapper'>
                            <Button 
                                label="Invia"
                                className="primary-button"
                                onClick={resetPassword}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer limit={1}></ToastContainer>
        </div>
    );
}

export default PasswordRecoveryPage;