import { useState } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { InputSelect } from "../../components/Input/InputSelect";

import * as UserService from "../../services/UserService";

import { RegistrationForm } from "../../types/RegistrationForm";

import { genders } from "../../utils/Constants";
import { getOptionFromValue, notifyErrorMessage, notifySuccessMessage } from "../../helpers/commonHelpers";

interface Props {
    registrationForm: RegistrationForm,
    updateRegistrationForm: (registrationForm: RegistrationForm) => void,
}

export const ProfilePersonalDataForm: React.FC<Props> = ({registrationForm, updateRegistrationForm}: Props) => {
    // const [bmiError, setBmiError] = useState<string>('');
    const [isSavingUser, setIsSavingUser] = useState<boolean>(false);

    // const calculateBmi = () => {
    //     setBmiError('');
    //     if(!registrationForm.weight || !registrationForm.height) {
    //         setBmiError('Inserisci Peso e Altezza per calcolare il BMI');
    //         return;
    //     }
    //     const bmi = Number(registrationForm.weight) / (Number(registrationForm.height)/100 * Number(registrationForm.height)/100);
    //     updateRegistrationForm({bmi: (Math.round(bmi*100)/100).toString()})
    // }

    const updateUser = () => {
        setIsSavingUser(true);
        UserService.updateUser(registrationForm)
            .then(response => {
                notifySuccessMessage('Dati personali aggiornati!');
            })
            .catch(error => {
                notifyErrorMessage(error.message);
            })
            .finally(() => setIsSavingUser(false));
    }

    const validationSchema = Yup.object().shape({
        firstname: Yup.string().required('Il nome è obbligatorio'),
        lastname: Yup.string().required('Il cognome è obbligatorio'),
        // weight: Yup.string().required('Il peso è obbligatorio'),
        // height: Yup.string().required('L\'altezza è obbligatoria'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors
    } = useForm<any>({
        resolver: yupResolver(validationSchema)
    });

    return <div id='personal-data-form' className='personal-data-form'>
        <div className='form-inputs'>
            <Input
                label="Nome*"
                name="firstname"
                register={register}
                placeholder='Inserisci il tuo nome'
                type="text"
                value={registrationForm.firstname}
                error={errors.firstname?.message}
                onChange={(e) => updateRegistrationForm({firstname: e.target.value})}
            />
            <Input
                label="Cognome*"
                name="lastname"
                register={register}
                placeholder='Inserisci il tuo cognome'
                type="text"
                value={registrationForm.lastname}
                error={errors.lastname?.message}
                onChange={(e) => updateRegistrationForm({lastname: e.target.value})}
            />
            <Input
                label="Email*"
                name="email"
                register={register}
                disabled={true}
                type="text"
                value={registrationForm.email}
                error={errors.lastname?.message}
                onChange={(e) => updateRegistrationForm({lastname: e.target.value})}
            />
            {/* <InputSelect
                label='Genere*'
                name='gender'
                register={register}
                value={getOptionFromValue(genders, registrationForm?.gender)}
                error={errors.gender?.message}
                placeholder="Seleziona Genere"
                options={genders}
                onChange={(option) => updateRegistrationForm({gender: option.value})}
            />
            <Input 
                label='Data di nascita*'
                type='date'
                name='birthdate'
                register={register}
                value={registrationForm.birthdate}
                error={errors.birthdate?.message}
                onChange={(e) => updateRegistrationForm({birthdate: e.currentTarget.value})}
            />
            <Input
                label="Peso*"
                name="weight"
                register={register}
                placeholder='Inserisci il tuo peso'
                suffix="kg"
                type="text"
                value={registrationForm.weight?.toString()}
                error={errors.weight?.message}
                onChange={(e) => updateRegistrationForm({weight: e.target.value})}
            />
            <Input
                label="Altezza*"
                name="height"
                register={register}
                placeholder='Inserisci la tua altezza'
                suffix="cm"
                type="text"
                value={registrationForm.height?.toString()}
                error={errors.height?.message}
                onChange={(e) => updateRegistrationForm({height: e.target.value})}
            />
            <Input
                label="BMI"
                name="bmi"
                register={register}
                placeholder='00.00'
                type="text"
                disabled={true}
                value={registrationForm.bmi?.toString()}
                error={bmiError}
            />
            <div className='button-wrapper'>
                <Button 
                    type="button"
                    label="Calcola BMI"
                    className="secondary-button"
                    onClick={calculateBmi}
                />
            </div> */}
        </div>
        <div className='step-button-wrapper'>
            <Button 
                type="button"
                label="Salva"
                className="primary-button"
                isLoading={isSavingUser}
                onClick={handleSubmit(updateUser)}
            />
        </div>
    </div>
};
