import apiClient from "./apiClient";

import { setUserToken } from "../services/TokenService";
import { RegistrationForm } from "../types/RegistrationForm";

export const register = async (registrationForm: RegistrationForm): Promise<String> => {
    const requestData = {
        // birthdate: registrationForm.birthdate,
        // bmi: registrationForm.bmi,
        confirmPassword: registrationForm.confirmPassword,
        email: registrationForm.email,
        firstname: registrationForm.firstname,
        // gender: registrationForm.gender,
        // height: registrationForm.height,
        lastname: registrationForm.lastname,
        // weight: registrationForm.weight,
        password: registrationForm.password,
        privacy: registrationForm.privacy,

    };

    return await apiClient.post('/auth/register', requestData)
        .then((response) => {
            const responseData = response.data;
            setUserToken(responseData);
            return responseData;
        })
        .catch(error => {
            if (error.response && error.response.data.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Qualcosa è andato storto. Riprova');
        });
};