import { Navigate, Route, Routes } from "react-router-dom";

import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";

import DashboardPageLayout from "./layouts/DashboardPageLayout";

import LoginPage  from './pages/LoginPage/LoginPage';
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import DashboardRecipesPage from './pages/DashboardRecipesPage/DashboardRecipesPage';
import DashboardTipsPage from "./pages/DashboardTipsPage/DashboardTipsPage";
import DashboardMainPage from './pages/DashboardFoodDiaryPage/DashboardFoodDiaryPage';
import DashboardProfilePage from "./pages/DashboardProfilePage/DashboardProfilePage";
import DashboardCalendarPage from "./pages/DashboardCalendarPage";
import RegistrationThankYouPage from "./pages/RegistrationThankYouPage/RegistrationThankYouPage";
import DashboardContestPage from "./pages/DashboardContestPage/DashboardContestPage";
import PasswordRecoveryPage from "./pages/PasswordRecoveryPage/PasswordRecoveryPage";
import PasswordResetPage from "./pages/PasswordResetPage/PasswordResetPage";

import { isLoggedIn } from "./services/AuthService";

import './App.scss';

function App() {
  return (
    <Routes>
      <Route path="/accedi" element={<LoginPage />} />
      <Route path="/registrati" element={<RegistrationPage />} />
      <Route path="/recupera-password" element={<PasswordRecoveryPage />} />
      <Route path="/reset-password" element={<PasswordResetPage />} />
      <Route path="*" element={<Navigate to="/diario-alimentare" replace />}/>
      <Route element={<ProtectedRoute isAllowedFunction={() => isLoggedIn()} />}>
        <Route path="/registrazione-completata" element={<RegistrationThankYouPage />} />
        <Route element={<DashboardPageLayout />}>
          <Route path="/diario-alimentare" element={<DashboardMainPage />} />
          <Route path="/contest" element={<DashboardContestPage />} />
          <Route path="/ricette" element={<DashboardRecipesPage />} />
          <Route path="/tips" element={<DashboardTipsPage />} />
          <Route path="/profilo" element={<DashboardProfilePage />} />
          <Route path="/calendar" element={<DashboardCalendarPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
