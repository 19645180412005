import "./LoadingSpinner.scss";

interface Props {
    color?: string
}

export const LoadingSpinner = ({color}: Props) => {
    return (
        <div className="loader" style={{borderTopColor: color}}></div>
    );
};