import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingOverlay } from '../../components/LoadingOverlay/LoadingOverlay';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';

import Logo from "../../images/logo-fun-vegetables.png";

import * as Auth from "../../services/AuthService";
import { register } from '../../services/RegistrationService';
import { setLocalUser } from '../../services/UserService';

import { RegistrationForm } from '../../types/RegistrationForm';

import { StepButton } from './StepButton/StepButton';
import { PersonalDataForm } from './PersonalDataForm/PersonalDataForm';
import { LoginDataForm } from './LoginDataForm/LoginDataForm';
import { SignupForm } from './SignupForm/SignupForm';

import './RegistrationPage.scss';

function RegistrationPage() {
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [registrationForm, setRegistrationForm] = useState<RegistrationForm>({
        firstname: '',
        lastname: '',
        gender: {label: '', value: ''},
        birthdate: '',
        weight: '',
        height: '',
        bmi: '00.00',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const updateRegistrationForm = (updatedRegistrationForm: RegistrationForm) => {
        setRegistrationForm({...registrationForm, 
          ...(updatedRegistrationForm.firstname !== undefined && {firstname: updatedRegistrationForm.firstname}),
          ...(updatedRegistrationForm.lastname !== undefined && {lastname: updatedRegistrationForm.lastname}),
          ...(updatedRegistrationForm.gender !== undefined && {gender: updatedRegistrationForm.gender}),
          ...(updatedRegistrationForm.birthdate !== undefined && {birthdate: updatedRegistrationForm.birthdate}),
          ...(updatedRegistrationForm.weight !== undefined && {weight: updatedRegistrationForm.weight}),
          ...(updatedRegistrationForm.height !== undefined && {height: updatedRegistrationForm.height}),
          ...(updatedRegistrationForm.bmi !== undefined && {bmi: updatedRegistrationForm.bmi}),
          ...(updatedRegistrationForm.email !== undefined && {email: updatedRegistrationForm.email}),
          ...(updatedRegistrationForm.password !== undefined && {password: updatedRegistrationForm.password}),
          ...(updatedRegistrationForm.confirmPassword !== undefined && {confirmPassword: updatedRegistrationForm.confirmPassword}),
          ...(updatedRegistrationForm.privacy !== undefined && {privacy: updatedRegistrationForm.privacy}),
        });
      }

    const submitRegistration = () => {
        setIsLoading(true);
        setErrorMessage('');
        register(registrationForm)
            .then(() => {
                Auth.getUser()
                    .then(user => {
                        setLocalUser(user);
                    })
                navigate('/registrazione-completata');
            })
            .catch(error => {
                console.log(error.message);
                setErrorMessage(error.message)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (  
        <div className="registration-page">
            {isLoading &&
                <LoadingOverlay 
                    text='Registrazione in corso...'
                />
            }
            <div className='content-wrapper'>
                <div className="logo-section">
                    <img className='logo-image' src={Logo} alt="Logo Fun Vegetables" />
                </div>
                <form className="form-section">
                    <div className='form-title'>
                        <p className='font-bold title'>Registrati</p>
                        <p className='subtitle'>Compila i campi a seguire per creare un nuovo account</p>
                    </div>
                    {/* <div className='steps-wrapper'>
                        <StepButton 
                            title='Step 1'
                            subtitle='Dati Anagrafici'
                            active={currentStep === 1}
                            completed={currentStep > 1}
                            onClick={() => setCurrentStep(1)}
                        />
                        <StepButton 
                            title='Step 2'
                            subtitle='Dati di accesso'
                            active={currentStep === 2}
                        />
                    </div> */}
                    <SignupForm 
                        registrationForm={registrationForm}
                        updateRegistrationForm={(updatedRegistrationForm) => updateRegistrationForm(updatedRegistrationForm)}
                        submitButtonClick={submitRegistration}

                    />
                    {/* REGISTRAZIONE A STEP */}
                    {/* {currentStep === 1 &&
                        <PersonalDataForm 
                            registrationForm={registrationForm}
                            updateRegistrationForm={(updatedRegistrationForm) => updateRegistrationForm(updatedRegistrationForm)}
                            nextStepButtonClick={() => setCurrentStep(2)}
                        />
                    }
                    {currentStep === 2 &&
                        <LoginDataForm 
                            registrationForm={registrationForm}
                            updateRegistrationForm={(updatedRegistrationForm) => updateRegistrationForm(updatedRegistrationForm)}
                            previousStepButtonClick={() => setCurrentStep(1)}
                            submitButtonClick={submitRegistration}
                        />
                    } */}
                    {errorMessage &&
                        <ErrorMessage 
                            message={errorMessage}
                        />
                    }
                    <div className='footer-link-wrapper text-xs'>
                        <p>Hai già un account?</p>
                        <a className='font-bold' href='/accedi'>Accedi ora</a>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default RegistrationPage;