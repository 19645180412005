import { useState } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button } from "../../components/Button/Button";
import { InputPassword } from "../../components/Input/InputPassword";

import { notifyErrorMessage, notifySuccessMessage } from "../../helpers/commonHelpers";

import * as UserService from "../../services/UserService";

export const ProfileLoginDataForm: React.FC = () => {
    const [isUpdatingPassword, setIsUpdatingPassword] = useState<boolean>(false);
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const updateUser = () => {
        setIsUpdatingPassword(true);
        UserService.updatePassword(oldPassword, newPassword)
            .then(response => {
                notifySuccessMessage('Password aggiornata correttamente!');
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            })
            .catch(error => {
                notifyErrorMessage(error.message);
            })
            .finally(() => {
                setIsUpdatingPassword(false)
            });
    }

    const validationSchema = Yup.object().shape({   
        oldPassword: Yup.string()
            .required('La password attuale è obbligatoria'),
        newPassword: Yup.string()
            .required('La nuova password è obbligatoria')
            .min(6, 'La password deve contenere almeno 6 caratteri'),
        confirmPassword: Yup.string()
            .required('Campo obbligatorio')
            .oneOf([Yup.ref('newPassword')], 'Le password non coincidono'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors
    } = useForm<any>({
        resolver: yupResolver(validationSchema)
    });

    return <div id='personal-data-form' className='personal-data-form'>
        <div className='form-inputs'>
            <InputPassword
                label='Password Attuale*'
                name='oldPassword'
                register={register}
                value={oldPassword}
                error={errors.oldPassword?.message}
                onChange={(e) => setOldPassword(e.target.value)}
            />
            <InputPassword
                label='Nuova Password*'
                name='newPassword'
                register={register}
                value={newPassword}
                error={errors.newPassword?.message}
                onChange={(e) => setNewPassword(e.target.value)}
            />
            <InputPassword
                label='Conferma password*'
                name='confirmPassword'
                register={register}
                value={confirmPassword}
                error={errors.confirmPassword?.message}
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
        </div>
        <div className='step-button-wrapper'>
            <Button 
                type="button"
                label="Salva"
                className="primary-button"
                isLoading={isUpdatingPassword}
                onClick={handleSubmit(updateUser)}
            />
        </div>
    </div>
};
