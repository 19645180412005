import { Button } from "../../../components/Button/Button";

import { Contest } from "../../../types/Contest";

import './ContestCard.scss';

interface Props {
    contest: Contest
}

export const ContestCard: React.FC<Props> = ({contest}: Props) => {

    return <div className='contest-card'>
        <div className="contest-image">
            <img src="https://placehold.co/500x300" />
        </div>
        <div className="contest-content">
            <p className="contest-title font-bold text-large">{contest.name}</p>
            {contest.description &&
                <p className="contest-description">{contest.description}</p>
            }
            <Button 
                className="primary-button"
                label="Partecipa al Contest"
            />
        </div>
    </div>
};
