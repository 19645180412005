import { useState } from 'react';

import { Modal } from '../../../../components/Modal/Modal';
import { Input } from '../../../../components/Input/Input';

import './AddFoodModal.scss';

interface Props {
    isOpen: boolean
    onCloseButtonClick?: () => void
    handleFoodSave: (name: string, quantity: string) => void
}

export const AddFoodModal: React.FC<Props> = ({isOpen, onCloseButtonClick, handleFoodSave}) => {
    const [name, setName] = useState<string>('');
    const [quantity, setQuantity] = useState<string>('');

    const onCloseModal = () => {
        setName('');
        setQuantity('');
        onCloseButtonClick?.();
    }

    const onPrimaryButtonClick = (name: string, quantity: string) => {
        handleFoodSave(name, quantity);
        setName('');
        setQuantity('');
    }

    return <Modal
        className='mini add-food-modal'
        isOpen={isOpen}
        title="Aggiungi Alimenti / Bevande"
        onCloseButtonClick={onCloseModal}
        primaryButtonText='Aggiungi'
        primaryButtonDisabled={!name || !quantity}
        onPrimaryButtonClick={() => onPrimaryButtonClick(name, quantity)}
    >
        <div className='form-wrapper'>
            <Input
                label="Nome*"
                name="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <Input
                label="Quantità* (in grammi)"
                name="quantity"
                type="number"
                suffix="gr"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
            />
        </div>
    </Modal>
};
